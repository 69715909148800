@font-face {
  font-display: swap;
  font-family: 'Playfair Display Medium';
  font-style: normal;
  font-weight: normal;
  src: url('/fonts/PlayfairDisplay-Medium.woff2') format('woff2');
}
@font-face {
  font-display: swap;
  font-family: 'Inter Semi-bold';
  font-style: normal;
  font-weight: normal;
  src: url('/fonts/Inter-SemiBold.woff2') format('woff2');
}
@font-face {
  font-display: swap;
  font-family: 'Inter Regular';
  font-style: normal;
  font-weight: normal;
  src: url('/fonts/Inter-Regular.woff2') format('woff2');
}